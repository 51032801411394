import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { io } from "socket.io-client";

import { context } from "../context/Context";
import { dbCall } from "../common/db";
import { IconDownload } from "@tabler/icons-react";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";

const img = "https://s3-alpha-sig.figma.com/img/a0a8/8adf/1d5adae1a5d869e0c08bee91af0c1df9?Expires=1717372800&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=ofcHh2sYoHosLfz7Vz8kNsxLYkzHb3OUJZS3FGAUWPA3jDici4yVWpm4e~HDXN~rTfrEDsqH9NGFW~tep5Lc-XnOnhVIOy0n5cmWpkM1yyeY9OGOJyEjY3MwNj~mwPWSdLhzpX5-vrxQqEF1pkb6-NCYLko9nma7PkGqNl2-EFF6~J7dsCZ68De-i70TJRzD7PYl~Hzi7u5v170kKbKiZKCn2YPsvhy-rZOBzYZ58jebWcIf1JbYTUe0StbU2-keJh0JOopYGkMSLeo0Cf9F5k-JFD1EGHxiaHvVsb30y8irSEmHMQAPUYPNv3He5IITDqoG9oS5XY6baKuF8kzuZA__"

const randomString = () => {
	return Date.now().toString(36) + Math.random().toString(36).substring(2);
}
let currentMsg = []

function Chat() {
	const params = useParams()
	const [toggle, setToggle] = useState(params?.id ? false : true);
	const [message, setMessage] = useState("");
	const [room, setRoom] = useState("");
	const [socketID, setSocketId] = useState("");
	const [users, setUsers] = useState([])
	const [currentUser, setCurrentUser] = useState("")
	const [chat, setChat] = useState([])
	const [chatId, setChatId] = useState(-1)
	const [attachment, setAttachment] = useState(null)
	const usersRef = useRef(users);
	const chatIdRef = useRef(chatId);

	const { user } = useContext(context);
	const socket = useMemo(() => io("http://localhost:5000", {
		query: {
			userId: user?.email,
			plateform: "partner"
		}
	}), [user]);



	const saveMssg = async (id, chats) => {
		const fetch_mssg = await dbCall.put("/partner/marketplace/update-chat", {
			id: id,
			chats: chats
		})

	}

	const updateStatus = async (status, userId) => {
		const reponse = await dbCall.put("/partner/marketplace/update-status", {
			status: status,
			userId: userId
		})
	}

	const handleSubmit = (e, roomId, key) => {
		e.preventDefault();
		const chat_Id = chatId
		const timestamp = new Date().toLocaleTimeString('en-US', { hour12: false, hour: "numeric", minute: "numeric" })
		const data = { message, roomId, user: user?.email, timestamp: timestamp, attachment: key }

		if (data?.message.length == 0 && !attachment) {
			return
		}
		socket.emit("send_message", data);

		const updated_message = [...currentMsg, {
			id: randomString(),
			message: data.message,
			sentBy: user?.email,
			timestamp: timestamp,
			attachment: key,
			read: false
		}]

		const currentUser2 = sessionStorage.getItem("currentUser");
		for (let i = 0; i < users.length; i++) {
			if (users[i]?.User?.email == currentUser2) {
				users[i].Chats.chats.push({
					id: randomString(),
					message: data.message,
					sentBy: currentUser?.email,
					timestamp: timestamp,
					attachment: key,
					read: true
				})
			}
		}

		setChat(updated_message)
		currentMsg = updated_message
		saveMssg(chat_Id, currentMsg)
		setMessage("");
		setChatId(chat_Id);
	};

	const fetchMessageData = (arr) => {

		return (
			arr.sort((x, y) => y.Chats?.updatedAt - x.Chats?.updatedAt)
		)
		// .reverse()
	}
	const getChatUser = async (userSet) => {
		try {
			const fetch = await dbCall("/partner/marketplace/get-chat-users")
			console.log(fetch?.data?.ChatUser, "Chatiser")
			const response = await fetch?.data?.ChatUser
			const filter = await fetchMessageData(JSON.parse(JSON.stringify(response)))
			if (userSet) {
				setUsers(filter)
				usersRef.current = filter
			}
			return response
		} catch (e) {
			console.log(e)
		}
	}

	const uploadMediafromDevice = async (profilePic) => {
		// console.log("files : ", profilePic);

		if (profilePic == null) {
			toast.error('Select Image to change your Profil picture.');
			return;
		}


		const filesData = new FormData(); 		// use FormData else it will give 'data too long' error

		filesData.append("profilePic", profilePic);
		try {

			const response = await dbCall.post('/marketplace/upload-user-media', filesData)
			console.log(response)
			return response?.data?.key;
		} catch (error) {
			if (error.response) {
				toast.error(error.response.data.message)
			} else {
				toast.error('Something went wrong!');
			}
		}
	};

	// useEffect(() => {
	// }, [])

	useEffect(() => {
		getChatUser(false);
		sessionStorage.clear("currentUser")

		socket.on("connect", () => {
			socket.emit("join_room", user?.email)
			updateStatus(true, null)
		});

		socket.on("receive_message", async (data) => {
			console.log(data, "Messg received")
			const currentUser2 = sessionStorage.getItem("currentUser");
			if (currentUser2 == data?.user) {
				const updated_message = [...currentMsg, {
					id: randomString(),
					message: data.message,
					sentBy: currentUser2,
					timestamp: data?.timestamp,
					attachment: data.attachment,
					read: currentUser2 == data?.user ? true : false
				}]

				setChat(updated_message)
				currentMsg = updated_message
				saveMssg(chatIdRef.current, updated_message)
			}
			// else {
			const users = await usersRef.current;
			for (let i = 0; i < users.length; i++) {
				if (users[i]?.User?.email == data?.user) {

					const readReceipt = users[i].User.email == currentUser2
					console.log(readReceipt, users[i].Chats.chats[users[i].Chats.chats.length - 1], users[i].User.email, currentUser2, "revfae")
					// users[i].Chats.chats[users[i].Chats.chats.length -1]["read"] = true;
					users[i].Chats.chats.push({
						id: randomString(),
						message: data.message,
						sentBy: data.user,
						attachment: data.attachment,
						timestamp: data?.timestamp,
						read: readReceipt
					})
					users[i].Chats.updatedAt = (Date.now() / 1000).toFixed();
				}
			}
			const filter = await fetchMessageData(users)
			setUsers([...filter])
			usersRef.current = filter

			Notification.requestPermission().then(perm => {
				if (perm == "granted") {
					new Notification(`You have a new Message From ${data?.user}
					message: ${data?.message}
					`)
				}
			})
			// }
		});

		socket.on("getOnlineUser", async (data) => {
			const users = await getChatUser(true)
			const objIndex = users.findIndex(obj => obj?.User?.email == data?.userId);
			// const objIndex = users.findIndex(obj => obj?.User?.email == "divyansh@360circleinfotech.com");
			console.log(objIndex, users, data?.userId, "getOnlineUser")
			if (objIndex != -1) {
				users[objIndex].User.isOnline = true;

				if (currentUser?.email == data?.userId) {
					setCurrentUser({
						...currentUser,
						isOnline: true
					})
				}

				setUsers([...users])
			}
		});
		socket.on("getOfflineUser", async (data) => {
			const users = await getChatUser(true)
			const objIndex = users.findIndex(obj => obj?.User?.email == data?.userId);

			console.log(objIndex, users, data?.userId, "getOfflineUser")
			if (objIndex != -1) {

				users[objIndex].User.isOnline = false;

				if (currentUser?.email == data?.userId) {
					setCurrentUser({
						...currentUser,
						isOnline: false,
						lastSeen: new Date(Date.now()).toLocaleString("en-US")
					})
				}

				setUsers([...users])
				// updateStatus(false, users[objIndex]?.User?.id)

			}
		});

		return () => {
			socket.disconnect();
		};
	}, [user]);

	// useEffect(() => {
	// 	setTimeout(()=>{

	// 		Notification.requestPermission().then(perm =>{
	// 			if(perm == "granted"){
	// 				new Notification("You have new Message Here")
	// 			}
	// 		})
	// 	},5000)
	// }, []);

	useEffect(() => {
		const handleSendMssg = async (e) => {
			if (e.key == "Enter") {
				let key;
				if (attachment) {
					key = await uploadMediafromDevice(attachment)
					// key = attachment?.name
				}
				handleSubmit(e, currentUser?.email, key)
				setAttachment(null)
			}
		}

		document.addEventListener("keydown", handleSendMssg);

		return () => document.removeEventListener("keydown", handleSendMssg);
	})

	useEffect(() => {
		var element = document.getElementById("scrollDiv");
		element?.scrollTo(0, element.scrollHeight)
	}, [JSON.stringify(chat)])

	// if (!user?.email) {
	// 	return (
	// 		<p>loading....</p>
	// 	)
	// }
	return (
		<div
			className="w-full flex transition-all delay-75"
			style={{ fontFamily: "Inter" }}
		>
			{/* <div className="h-screen relative"></div> */}
			<div
				// className={`xl:w-1/4 lg:w-1/3 z-40 md:w-2/5 sm:w-1/2 xs:w-3/4 w-11/12 lg:static absolute top-0 bottom-0 ${toggle ? "left-0 border" : "left-[-40rem]"
				// 	} bg-[#F3F4F6] text-black font-medium py-3 px-4 h-screen overflow-y-auto`}

				// className={` w-full    ${toggle ? "left-0 border" : "hidden -translate-x-[100%] "
				className={` w-full    ${toggle ? "left-0 border" : "hidden "
					} bg-[#F3F4F6] text-black font-medium py-3 px-4 h-screen border-r-2 border-zinc-200`}
			>
				<div className="flex items-center justify-between">
					<h2 className="font-semibold my-6" onClick={() => { console.log(user) }}>My Chat List</h2>
					{/* <span
						className="mdi mdi-close text-xl cursor-pointer  block"
						onClick={() => setToggle(false)}
					></span> */}
				</div>
				{
					[...users]?.map((userDB, i) => (
						<>
							<Card
								user={userDB?.User}
								currentUser={currentUser}
								setCurrentUser={setCurrentUser}
								chat={userDB?.Chats}
								chats={chat}
								setChat={setChat}
								setChatId={setChatId}
								logInUser={user}
								chatIdRef={chatIdRef}
								setToggle={setToggle}
								toggle={toggle}
							/>
						</>
					))
				}
			</div>
			{/* <div className="xl:w-3/4 lg:w-2/3 w-full bg-white px-4 py-3 h-screen"> */}
			{
				currentUser?.email &&
				<div className="z-[2] top-[65px]   w-full bg-white">
					<div className="bg-[#F3F4F6] rounded-md h-full ">
						{
							currentUser ?
								<div className="flex flex-col h-full">
									{/*  */}
									<div className="flex h-20 items-center gap-3 px-6 pt-3 border-b-2 border-zinc-300 bg-[#F9F9F9]">
										<Link
											to="/user/chat"
											className={`mdi mdi-arrow-left text-[#7A7A7A] cursor-pointer mr-2 text-xl block`}
										// onClick={() => setToggle(!toggle)}
										></Link>
										<div className="relative">
											{
												currentUser?.profilePic ?
													<img
														src={`https://usercdn.qafto.com/${currentUser?.profilePic}`}
														className="rounded-full w-11 h-11 object-cover"
														alt="man.png"
													/>
													:
													<span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-black">
														<span className="text-sm font-medium uppercase leading-none text-white">
															{
																currentUser?.firstName?.split(' ').length === 1 ? currentUser?.firstName?.split(' ')[0][0] :
																	currentUser?.firstName?.split(' ')[0][0] + currentUser?.firstName?.split(' ')[currentUser?.firstName?.split(' ').length - 1][0]
															}
														</span>
													</span>
											}
											{/* {
											currentUser?.isOnline &&
											<div className="absolute top-0 right-0 rounded-full bg-[#6ED06C] w-3 h-3"></div>
										} */}
										</div>

										<div className="">
											<h2 className="font-medium">{currentUser?.firstName} {currentUser?.lastName}</h2>
											{
												currentUser?.isOnline ?
													<p className="text-[#7A7A7A] text-sm truncate text-nowrap">
														Active Now{" "}
													</p>
													:
													<p className="text-[#7A7A7A] text-sm truncate text-nowrap">
														lastSeen {currentUser?.lastSeen}
													</p>
											}
										</div>
									</div>

									<div id="scrollDiv" className="flex  flex-col gap-y-1 px-3 py-3 overflow-x-hidden h-[76vh] no-scrollbar">
										{
											currentUser?.email &&
											chat?.map((chat, i) => (
												<>
													{
														chat.sentBy == user?.email ?
															< div className="flex items-center gap-3 ml-auto mt-1" >
																<div className="bg-white text-[#7A7A7A] whitespace-normal p-2 sm:w-3/4 w-4/5 ml-auto rounded-md">
																	<p className=" text-sm text-container">
																		{chat?.message}
																	</p>
																	{
																		chat?.attachment &&
																		<div className="my-2 bg-[#F1F1F1] p-2 flex items-center gap-2  justify-between">
																			<div className="flex items-center">
																				<p className="bg-[#1E429F] text-white font-medium rounded-md py-1 px-2 text-sm">
																					PS
																				</p>
																				<p className="text-sm truncate w-32 ml-2">{chat?.attachment?.split("/")[chat?.attachment?.split("/")?.length - 1]} </p>
																			</div>

																			<a href={`https://usercdn.qafto.com/${chat?.attachment}`}
																				download
																				className="flex items-center mr-1"
																			>
																				<IconDownload className="h-5 w-5 text-gray-700" />
																				<p className="opacity-0">Do</p>
																			</a>
																		</div>
																	}
																	<p className="text-right text-xs mt-1">{chat?.timestamp}</p>
																</div>
																<span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-black">
																	<span className="text-sm font-medium uppercase leading-none text-white">
																		{
																			user?.profilePic ?
																				<img
																					src={`https://usercdn.qafto.com/${user?.profilePic}`}
																					className="rounded-full w-full aspect-circle object-cover"
																					alt="man.png"
																				/>
																				:
																				user?.first_name?.split(' ').length === 1 ? user?.first_name?.split(' ')[0][0] :
																					user?.first_name?.split(' ')[0][0] + user?.first_name?.split(' ')[user?.first_name?.split(' ').length - 1][0]
																		}
																	</span>
																</span>
															</div>
															:
															<div className="flex items-center gap-3  mt-1 mr-auto">
																{
																	currentUser?.profilePic ?
																		<img
																			src={`https://usercdn.qafto.com/${currentUser?.profilePic}`}
																			className="rounded-full w-11 h-11 object-cover"
																			alt="man.png"
																		/>
																		:
																		<span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-black">
																			<span className="text-sm font-medium uppercase leading-none text-white">
																				{
																					currentUser?.firstName?.split(' ').length === 1 ? currentUser?.firstName?.split(' ')[0][0] :
																						currentUser?.firstName?.split(' ')[0][0] + currentUser?.firstName?.split(' ')[currentUser?.firstName?.split(' ').length - 1][0]
																				}
																			</span>
																		</span>
																}
																<div className="bg-[#EBF3FE] text-[#7A7A7A] p-2 sm:w-3/4 w-4/5 mr-auto rounded-md">
																	<p className=" text-sm text-container">
																		{chat?.message}
																	</p>
																	{
																		chat?.attachment &&
																		<div className="my-2 bg-[#F1F1F1] p-2 flex items-center gap-2  justify-between">
																			<div className="flex items-center">
																				<p className="bg-[#1E429F] text-white font-medium rounded-md py-1 px-2 text-sm">
																					PS
																				</p>
																				<p className="text-sm truncate w-32 ml-2">{chat?.attachment?.split("/")[chat?.attachment?.split("/")?.length - 1]} </p>
																			</div>

																			<a href={`https://usercdn.qafto.com/${chat?.attachment}`}
																				download
																				className="flex items-center mr-1"
																			>
																				<IconDownload className="h-5 w-5 text-gray-700" />
																			</a>
																		</div>
																	}
																	<p className="text-right text-xs mt-1">{chat?.timestamp}</p>
																</div>
															</div>
													}
												</>
											))
										}
									</div>

									<div className="flex items-center py-2 px-2 space-x-3 w-full">
										<input
											type="text"
											placeholder="Type a message"
											className="outline-none py-3 px-4 w-full bg-gray-100 border border-[#757575] rounded-lg"
											value={message}
											onChange={(e) => setMessage(e.target.value)}
										/>

										<div class="flex items-center justify-center">
											<label for="dropzone-file" class="">
												<div className="mdi mdi-attachment mdi-rotate-135 text-[26px] cursor-pointer hover:bg-gray-200 py-1 px-2.5 rounded-lg border border-[#757575]" />
												<input id="dropzone-file"
													type="file"
													className="hidden"
													onChange={(e) => {
														// console.log(e.target.files[0]);
														setAttachment(e.target.files[0])
														// uploadMediafromDevice(e.target.files[0])
													}}
												/>
											</label>
										</div>
										<button className="bg-[#1E429F] hover:bg-[#153588] text-white flex items-center gap-2 py-3 px-4 rounded-md"
											onClick={async (e) => {
												let key;
												if (attachment) {
													key = await uploadMediafromDevice(attachment)
													// key = attachment?.name
												}

												handleSubmit(e, currentUser?.email, key)
												setAttachment(null)
											}}
										>
											<span className="font-medium max-[420px]:hidden">Send</span>
											<span class="mdi mdi-send-outline mdi-rotate-315 -mr-1" />
										</button>
									</div>
								</div>
								:
								""
						}
					</div>
				</div>
			}
		</div >
	);
}

export default Chat;

const Card = ({ user, chats, currentUser, setCurrentUser, chat, setChat, setChatId, logInUser, chatIdRef, toggle, setToggle }) => {
	const [unreadMessages, setUnreadMessages] = useState(0)
	const [lastMessage, setLastMessage] = useState("")
	const { totalMsg, setTotalMsg } = useContext(context);

	const params = useParams()
	const navigate = useNavigate();

	const saveMssg = async (id, chats) => {
		const fetch_mssg = await dbCall.put("/partner/marketplace/update-chat", {
			id: id,
			chats: chats
		})

	}
	useEffect(() => {
		console.log(chat?.chats, logInUser, "My chats")
		const unreadMsg = chat?.chats
		let val = 0
		for (let i = 0; i < unreadMsg?.length; i++) {
			if (!unreadMsg?.[i]?.read && chat?.chats?.[i]?.sentBy != logInUser?.email) {
				val += 1
			}
		}
		setUnreadMessages(val)
	}, [JSON.stringify(chat?.chats), chat?.chats.length])

	useEffect(() => {
		console.log(params)
		if (!params?.id) {
			setCurrentUser("")
			setChat([])
			setToggle(true)
		}
		if (params?.id == user?.uniqueId) {
			let readMsgCount = 0
			if (currentUser?.email != user?.email) {
				for (let i = 0; i < chat?.chats.length; i++) {
					if (!chat?.chats[i].read && chat?.chats?.[i]?.sentBy != logInUser?.email) {
						chat.chats[i].read = true
					}
				}

				setTotalMsg(totalMsg - readMsgCount)
				// console.log(chat?.chats, logInUser, "CGATS")
				saveMssg(chat?.id, chat?.chats)
				sessionStorage.setItem("currentUser", user?.email)
				setCurrentUser(user)
				setChat(chat?.chats)
				setChatId(chat?.id)
				chatIdRef.current = chat?.id
				currentMsg = chat?.chats
			}
		}
		// console.log(user, "uyse")
	}, [params?.id])
	return (
		<div className="card bg-white flex justify-between p-2 gap-2 shadow-md cursor-pointer my-3 overflow-hidden"
			onClick={() => {
				console.log(user)
				navigate(`/user/chat/${user?.uniqueId}`)
				setToggle(!toggle)
				// if (currentUser?.email != user?.email) {
				// 	for (let i = 0; i < chat?.chats.length; i++) {
				// 		if (!chat?.chats[i].read && chat?.chats?.[i]?.sentBy != logInUser?.email) {
				// 			chat.chats[i].read = true
				// 		}
				// 	}
				// 	saveMssg(chat?.id, chat.chats)
				// 	sessionStorage.setItem("currentUser", user?.email)
				// 	setCurrentUser(user)
				// 	setChat(chat?.chats)
				// 	setChatId(chat?.id)
				// 	chatIdRef.current = chat?.id
				// 	currentMsg = chat?.chats
				// }
			}}
		>
			<div className="flex items-center gap-3 ml-1 ">
				<div className="relative w-max">
					{
						user?.profilePic ?
							<img
								src={`https://usercdn.qafto.com/${user?.profilePic}`}
								className="rounded-full w-11 h-11 object-cover"
								alt="man.png"
							/>
							:
							<span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-black">
								<span className="text-sm font-medium uppercase leading-none text-white">
									{
										user?.firstName?.split(' ').length === 1 ? user?.firstName?.split(' ')[0][0] :
											user?.firstName?.split(' ')[0][0] + user?.firstName?.split(' ')[user?.firstName?.split(' ').length - 1][0]
									}
								</span>
							</span>
					}
					{
						user?.isOnline &&
						<div id={user.email} className="absolute top-0 right-0 rounded-full bg-[#6ED06C] w-3 h-3"></div>
					}
				</div>

				<div className="">
					<h2 className="">{user.firstName} {user?.lastName}</h2>
					<p className="text-[#7A7A7A] text-sm truncate text-nowrap w-32">
						{
							// user?.email == currentUser?.email ?
							// 	chats?.[chats?.length - 1]?.message
							// 	:
							chat?.chats?.[chat?.chats?.length - 1]?.message
						}
					</p>
				</div>
			</div>

			<div className="">
				<p className="text-xs text-[#7A7A7A] mb-2 w-max ml-auto">{chat?.chats?.[chat?.chats?.length - 1]?.timestamp}</p>
				{
					unreadMessages != 0 &&
					<p className="bg-[#6ED06C] rounded-full text-white text-xs w-max px-1 ml-auto">
						{unreadMessages}
					</p>
				}
			</div>
		</div>
	);
}