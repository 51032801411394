import React, { useState, createContext, useReducer, useEffect } from "react";
// import { dbCall } from "../common/db";
import axios from "axios";
import { AUTH_URL } from "../config";
import { AuthService } from "../common/auth.service";

export const context = createContext(null);

export const ContextProvider = ({ children }) => {
	// loading state
	const [isAnimating, setIsAnimating] = useState(false);

	// check if user profile is complete
	const [userProfileComplete, setUserProfileComplete] = useState(false);

	// User state
	const [user, setUser] = useState(null);
	const [totalMsg, setTotalMsg] = useState(0)

	useEffect(() => {
		const fetchData = async () => {
			try {
				const res = await AuthService.userVerify();
				setUserProfileComplete(res?.userProfileComplete)
				setUser(res);
			} catch (error) {
				console.log(error);
			}
		};
		fetchData();
	}, []);

	return (
		<context.Provider
			value={{
				// loading state
				isAnimating, setIsAnimating,
				// user state
				user, setUser,
				// check if user profile is complete
				userProfileComplete, setUserProfileComplete,
				totalMsg, setTotalMsg
			}}
		>
			{children}
		</context.Provider>
	);
};