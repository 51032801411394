import React, { useContext, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, useLocation, Navigate } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AuthService } from './common/auth.service';
import { ContextProvider } from './context/Context';

import './index.css';

import { context } from './context/Context';

// layouts
import UserLayout from './layouts/user';
import GuestLayout from './layouts/App';

import reportWebVitals from './reportWebVitals';
import TopLoadingBar from './components/TopLoadingBar';
import ProfileRedirect from './components/ProfileRedirect';
import Chat from './components/chat';

// landing pages
const LandingPage = React.lazy(() => import('./screens/guest/landingPage'));

// guest
const Login = React.lazy(() => import('./screens/guest/login'));
const Register = React.lazy(() => import('./screens/guest/register'));
const ForgotPassword = React.lazy(() => import('./screens/guest/forgotPassword'));
const Verify2FACode = React.lazy(() => import('./screens/guest/verify2FACode'));
const VerifyToken = React.lazy(() => import('./screens/guest/verifyToken'));

const ResetPassword = React.lazy(() => import('./screens/guest/resetPassword'));

// user
const UserProfile = React.lazy(() => import('./screens/account/profile'));
const EditProfile = React.lazy(() => import('./screens/account/EditProfile/editProfile'));
const Services = React.lazy(() => import('./screens/account/services'));
const Affiliate = React.lazy(() => import('./screens/account/affiliate'));
const MyContracts = React.lazy(() => import('./screens/account/myContracts'));
const Contract = React.lazy(() => import('./screens/account/contract'));


function RequireAuth({ children }) {
  const { setUserProfileComplete, user, setUser } = useContext(context);
  const [res, setRes] = useState(null);
  let location = useLocation();
  if (!user?.id) {
    const fetchUser = async () => {
      const response = await AuthService.userVerify()
      setRes(response)
    };
    fetchUser();
    if (res?.message == "User not found please login") {
      return <Navigate to="/guest/login" state={{ from: location }} replace />;
    } else {
      if (res?.id)
        setUser(res)
      setUserProfileComplete(user?.profileComplete);
      return children;
      // else return toast.error("Something went wrong")
    }
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
  } else {
    setUserProfileComplete(user?.profileComplete)
  }

  return children;
}
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <ContextProvider>
    <BrowserRouter>

      {/* toast notifications / alerts */}
      <ToastContainer />

      {/* progress bar */}
      <TopLoadingBar />

      {/* <NotificationModal /> */}

      {/* START - Auth Routes */}
      <Routes>
        <Route path="/" element={
          // AuthService.currentUserValue == null ? <GuestLayout /> : <Navigate to="/user" replace />
          <GuestLayout />
        }>
          <Route index element={
            <React.Suspense fallback={<>---</>}>
              <LandingPage />
            </React.Suspense>
          } />
        </Route>

        <Route path="/guest/"
          element={
            AuthService.currentUserValue == null ?
              <GuestLayout />
              :
              <ProfileRedirect />
            // <Navigate to={JSON.parse(AuthService.currentUserValue)?.profileComplete ? "/user/profile" : "/user/edit-profile"} replace />
          }
        >
          <Route path="login" element={
            <React.Suspense fallback={<>...</>}>
              <Login />
            </React.Suspense>
          } />
          <Route path="register" element={
            <React.Suspense fallback={<>...</>}>
              <Register />
            </React.Suspense>
          } />
          <Route path="forgot-password" element={
            <React.Suspense fallback={<>...</>}>
              <ForgotPassword />
            </React.Suspense>
          } />
          <Route path="reset-password" element={
            <React.Suspense fallback={<>...</>}>
              <ResetPassword />
            </React.Suspense>
          } />
          <Route path="login-verify-otp" element={
            <React.Suspense fallback={<>...</>}>
              <Verify2FACode />
            </React.Suspense>
          } />
          <Route path="recover-account/:token" element={
            <React.Suspense fallback={<>...</>}>
              <VerifyToken />
            </React.Suspense>
          } />
        </Route>
        {/* END - Auth Routes */}

        {/* START - User Routes */}
        <Route path="/user/"
          element={
            <RequireAuth>
              <UserLayout />
            </RequireAuth>
          }
        >
          {/* {
            JSON.parse(AuthService.currentUserValue)?.profileComplete ? */}
          <Route path="profile" element={
            <React.Suspense fallback={<>...</>}>
              <UserProfile />
            </React.Suspense>
          } />
          <Route path="chat/:id?" element={
            <React.Suspense fallback={<>...</>}>
              <Chat />
            </React.Suspense>
          } />
          {/* : */}
          <Route path="edit-profile" element={
            <React.Suspense fallback={<>...</>}>
              <EditProfile />
            </React.Suspense>
          } />
          {/* } */}

          <Route path="services" element={
            <React.Suspense fallback={<>...</>}>
              <Services />
            </React.Suspense>
          } />
          <Route path="affiliate" element={
            <React.Suspense fallback={<>...</>}>
              <Affiliate />
            </React.Suspense>
          } />
          <Route path="my-contracts" element={
            <React.Suspense fallback={<>...</>}>
              <MyContracts />
            </React.Suspense>
          } />
          <Route path="contract/:id" element={
            <React.Suspense fallback={<>...</>}>
              <Contract />
            </React.Suspense>
          } />
        </Route>
        {/* END - Auth Routes */}
      </Routes>
    </BrowserRouter>
  </ContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
