import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Transition } from '@headlessui/react';
import {
	IconArrowNarrowRight,
	IconChevronDown,
	IconUser,
} from "@tabler/icons-react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

import { classNames } from "../libraries/utility";
import { AuthService } from "../common/auth.service";
import { context } from "../context/Context";

const Header = () => {
	const [showMenu, setShowMenu] = useState(false);
	const { user, setUser } = useContext(context)
	function toggleMenu(type) {
		if (type == "show") {
			setShowMenu(true);
		}

		if (type == "hide") {
			setShowMenu(false);
		}
	}
	return (
		<header class="absolute inset-x-0 top-0 z-50">
			<nav
				class="flex items-center justify-between p-6 lg:px-16 "
				aria-label="Global"
			>
				<div></div>
				<div class="flex lg:flex-1">
					<a href="/" class="-m-1.5 p-1.5">
						<span class="sr-only">Qafto</span>
						<img class="h-12 w-auto" src="/partner-program-logo.png" alt="Qafto" />
					</a>
				</div>
				<div class="flex lg:hidden">
					<button
						type="button"
						class="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
						onClick={() => toggleMenu("show")}
					>
						<span class="sr-only">Open main menu</span>
						<svg
							class="h-6 w-6"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="currentColor"
							aria-hidden="true"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
							/>
						</svg>
					</button>
				</div>
				<div class="hidden lg:flex lg:gap-x-12">
					<Link
						to="/guest/register"
						class="text-sm font-semibold leading-6 text-gray-900 underline decoration-double decoration-green-600"
					>
						Join Us
					</Link>
					<a
						href="/#why-to-join-us"
						class="text-sm font-semibold leading-6 text-gray-900"
					>
						Why We?
					</a>
					<a
						href="/#how-to-join"
						class="text-sm font-semibold leading-6 text-gray-900"
					>
						How to Join?
					</a>
					<a
						href="/#need-help"
						class="text-sm font-semibold leading-6 text-gray-900"
					>
						Need Help?
					</a>

				</div>
				<div class="hidden lg:flex lg:flex-1 lg:justify-end items-center">
					{

						user?.id ?
							<>
								<div>
									<Link
										to="/user/profile"
										class=" text-sm font-semibold leading-6 text-black p-1 rounded-md px-3 items-center gap-1 flex border-[1px] border-black mx-4"
									>
										My Dashboard
										{/* <IconArrowNarrowRight size={20} /> */}
									</Link>
								</div>
								<Menu as="div" className="relative">
									<Menu.Button className="-m-1.5 flex items-center p-1.5">
										<span className="sr-only">Open user menu</span>

										<span className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-black">
											<span className="text-sm font-medium uppercase leading-none text-white">
												{
													user?.first_name?.split(' ').length === 1 ? user?.first_name?.split(' ')[0][0] :
														user?.first_name?.split(' ')[0][0] + user?.first_name?.split(' ')[user?.first_name?.split(' ').length - 1][0]
												}
											</span>
										</span>
										<span className="hidden lg:flex lg:items-center">
											<ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
										</span>
									</Menu.Button>
									<Transition
										as={Fragment}
										enter="transition ease-out duration-100"
										enterFrom="transform opacity-0 scale-95"
										enterTo="transform opacity-100 scale-100"
										leave="transition ease-in duration-75"
										leaveFrom="transform opacity-100 scale-100"
										leaveTo="transform opacity-0 scale-95"
									>
										<Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">

											<Menu.Item>
												{({ active }) => (
													<button
														onClick={() => {
															AuthService.logout();
															setUser(null);
														}}
														className={classNames(active ? 'bg-gray-50 cursor-pointer' : '', 'flex items-center px-3 py-1 text-sm leading-6 text-gray-800')}
													>
														<span className="h-4 w-5 me-1 -mt-1">
															<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-logout" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
																<path stroke="none" d="M0 0h24v24H0z" fill="none" />
																<path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
																<path d="M9 12h12l-3 -3" />
																<path d="M18 15l3 -3" />
															</svg>
														</span>
														Sign Out
													</button>
												)}
											</Menu.Item>
										</Menu.Items>
									</Transition>
								</Menu>
							</>
							:
							<div className="flex items-center justify-between">
								<div>
									<Link
										to="/guest/login"
										class="w-[100px] text-sm font-semibold leading-6 text-white p-2 rounded-md px-3   items-center gap-2 flex bg-black"
									>
										<IconUser size={20} />
										Login
										{/* <IconArrowNarrowRight size={20} /> */}
									</Link>
								</div>
								{/* <div>
									<Link
										to="/guest/register"
										class="ml-5 text-sm font-semibold leading-6 text-white p-2 rounded-md px-3   items-center gap-2 flex bg-black"
									>
										Register <IconArrowNarrowRight size={20} />
									</Link>
								</div> */}
							</div>
					}
				</div>
			</nav>
			<div
				role="dialog"
				aria-modal="true"
				className={showMenu ? "" : "hidden"}
			>
				<div class="fixed inset-0 z-50"></div>
				<div class="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
					<div class="flex items-center justify-between">
						<a href="/">
							<span class="sr-only">Qafto</span>
							<img
								class="h-10 w-auto"
								src="/partner-program-logo.png"
								alt="Qafto"
							/>
						</a>
						<button
							type="button"
							class="rounded-md text-gray-700"
							onClick={() => toggleMenu("hide")}
						>
							<span class="sr-only">Close menu</span>
							<svg
								class="h-6 w-6"
								fill="none"
								viewBox="0 0 24 24"
								strokeWidth="1.5"
								stroke="currentColor"
								aria-hidden="true"
							>
								<path
									strokeLinecap="round"
									strokeLinejoin="round"
									d="M6 18L18 6M6 6l12 12"
								/>
							</svg>
						</button>
					</div>
					<div class="mt-6 flow-root">
						<div class="-my-6 divide-y divide-gray-500/10">
							<div class="space-y-2 pt-6 pb-4">
								<div className={` ${(user && Object.keys(user).length > 0) ? 'border-y py-4' : 'border-t'} flex  items-center gap-4`}>
									{user?.id && Object.keys(user).length > 0 && <> <div className="flex items-center">
										<span className="inline-flex h-11 w-11 items-center justify-center rounded-full bg-gray-900">
											<span className="text-sm font-semibold uppercase leading-none text-white">
												{
													user?.first_name.split(' ').length === 1 ? user?.first_name.split(' ')[0][0] :
														user?.first_name.split(' ')[0][0] + user?.first_name.split(' ')[user?.first_name.split(' ').length - 1][0]
												}</span>
										</span>
									</div>
										<div>
											<span className=" font-semibold capitalize">
												{user?.first_name + " " + user?.last_name}
											</span>
										</div></>}
								</div>
								<Link
									to="/guest/register"
									onClick={() => setShowMenu(false)}
									class="block rounded-lg py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 underline decoration-double decoration-green-600"
								>
									Join Us
								</Link>
								<a
									onClick={() => setShowMenu(false)}
									href="/#why-to-join-us"
									class="block rounded-lg py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
								>
									Why We?
								</a>
								<a
									onClick={() => setShowMenu(false)}
									href="/#how-to-join"
									class="block rounded-lg py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
								>
									How to Join?
								</a>
								<a
									onClick={() => setShowMenu(false)}
									href="/#need-help"
									class="block rounded-lg py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
								>
									Need Help?
								</a>
								{
									user?.id &&
									<a
										onClick={() => setShowMenu(false)}
										href="/guest"
										class="block rounded-lg py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
									>
										My Dashboard
									</a>
								}
							</div>

							{
								user?.id ?
									<div className="flex items-center justify-between">
										<span className="flex items-center gap-2 rounded-lg py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer"
											onClick={() => { setUser(null); AuthService.logout() }}
										>
											<div className="h-5 w-6 me-1 -mt-1">
												<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-logout" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#2c3e50" fill="none" strokeLinecap="round" strokeLinejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none" />
													<path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2" />
													<path d="M9 12h12l-3 -3" />
													<path d="M18 15l3 -3" />
												</svg>
											</div>
											Sign Out
										</span>
									</div>
									:
									<div>
										<a
											onClick={() => setShowMenu(false)}
											href="/guest/login"
											class="mt-3 block rounded-lg py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 cursor-pointer"
										>
											Login
										</a>
										{/* <a
											onClick={() => setShowMenu(false)}
											href="/guest/register"
											class="block rounded-lg py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
										>
											Register
										</a> */}
									</div>
							}
						</div>
					</div>
				</div>
			</div>
		</header>
	);
};

export default Header;
