import {
	IconBrandFacebook,
	IconBrandInstagram,
	IconBrandLinkedin,
	IconBrandPinterest,
	IconBrandTwitter,
	IconBrandYoutube,
	IconCopyright
} from "@tabler/icons-react";

const Footer = () => {
	return (
		<div className="bg-[#F2F2F2]">
			<div class="mx-auto max-w-7xl px-6 pt-20 lg:px-8">
				<footer>
					<div className="mx-auto max-w-screen-xl space-y-8 lg:space-y-16">
						<div className="grid grid-cols-1 gap-8 lg:grid-cols-3">
							<div>
								<div className="text-teal-600">
									<img
										class="h-12"
										src="/partner-program-logo.png"
										alt="Qafto"
									/>
								</div>

								<p className="mt-4 max-w-xs text-base font-medium text-gray-500">
									Qafto is platform where you can create
									anything digitally and go live. After going
									live you can connect with your Audience as
									well
								</p>

								<ul className="mt-8 flex items-center gap-6 flex-wrap xs:flex-nowrap">
									<li>
										<a
											href="https://www.facebook.com/qafto"
											rel="noreferrer"
											target="_blank"
											className="text-gray-700 transition hover:opacity-75"
										>
											<span className="sr-only">
												Facebook
											</span>

											<IconBrandFacebook />
										</a>
									</li>

									<li>
										<a
											href="https://www.linkedin.com/showcase/qafto/"
											rel="noreferrer"
											target="_blank"
											className="text-gray-700 transition hover:opacity-75"
										>
											<span className="sr-only">
												LinkedIn
											</span>

											<IconBrandLinkedin />
										</a>
									</li>

									<li>
										<a
											href="https://www.youtube.com/@Qafto"
											rel="noreferrer"
											target="_blank"
											className="text-gray-700 transition hover:opacity-75"
										>
											<span className="sr-only">
												Youtube
											</span>

											<IconBrandYoutube />
										</a>
									</li>

									<li>
										<a
											href="https://twitter.com/QaftoDotCom"
											rel="noreferrer"
											target="_blank"
											className="text-gray-700 transition hover:opacity-75"
										>
											<span className="sr-only">
												Twitter
											</span>

											<svg xmlns="http://www.w3.org/2000/svg" class="" viewBox="0 0 24 24" version="1.1" height="22" width="22"  stroke="#2c3e50" fill="none" stroke-linecap="round" stroke-linejoin="round">
											<path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"></path>
										</svg>
										</a>
									</li>

									<li>
										<a
											href="https://www.instagram.com/qaftodotcom/"
											rel="noreferrer"
											target="_blank"
											className="text-gray-700 transition hover:opacity-75"
										>
											<span className="sr-only">
												Instagram
											</span>
											<IconBrandInstagram size={28} />
										</a>
									</li>
									<li>
										<a
											href="https://in.pinterest.com/QaftoDotCom/"
											rel="noreferrer"
											target="_blank"
											className="text-gray-700 transition hover:opacity-75"
										>
											<span className="sr-only">
												Instagram
											</span>
											<IconBrandPinterest size={28} />
										</a>
									</li>
								</ul>
							</div>

							<div className="grid grid-cols-1 gap-8 :grid-cols-2 md:col-span-2 md:grid-cols-3">
								<div>
									<p className="font-semibold font-inter text-lg text-black">
										Core Features
									</p>

									<ul className="mt-6 space-y-4 text-sm">
										<li>
											<a
												href="https://qafto.com/"
												className="text-gray-600 font-medium transition hover:opacity-75"
											>
												Website Builder
											</a>
										</li>

										<li>
											<a
												href="https://qafto.com/"
												className="text-gray-600 font-medium transition hover:opacity-75"
											>
												App Builder
											</a>
										</li>

										<li>
											<a
												href="https://qafto.com/"
												className="text-gray-600 font-medium transition hover:opacity-75"
											>
												Target Audience
											</a>
										</li>

										<li>
											<a
												href="/"
												className="text-gray-600 font-medium  transition hover:opacity-75"
											>
												Our Partners
											</a>
										</li>
										<li>
											<a
												href="https://digital-guru.qafto.com"
												className="text-gray-600 font-medium  transition hover:opacity-75"
											>
												Our Digital Guru's
											</a>
										</li>
									</ul>
								</div>

								<div>
									<p className="font-semibold text-lg text-gray-900">
										Support
									</p>

									<ul className="mt-6 space-y-4 text-sm">
										<li>
											<a
												href="https://qafto.com/pricing"
												className="text-gray-600 font-medium transition hover:opacity-75"
											>
												Pricing
											</a>
										</li>
										<li>
											<a
												href="https://qafto.com/about"
												className="text-gray-600 font-medium transition hover:opacity-75"
											>
												About
											</a>
										</li>

										<li>
											<a
												href="https://qafto.com/contact-us"
												className="text-gray-600 font-medium transition hover:opacity-75"
											>
												Contact
											</a>
										</li>

										<li>
											<a
												href="https://qafto.com/blog"
												className="text-gray-600 font-medium transition hover:opacity-75"
											>
												Blog
											</a>
										</li>
										<li>
											<a
												href="https://qafto.com/#faq"
												className="text-gray-600 font-medium transition hover:opacity-75"
											>
												FAQ
											</a>
										</li>
									</ul>
								</div>

								<div>
									<p className="font-semibold text-lg text-gray-900">
										Legal
									</p>

									<ul className="mt-6 space-y-4 text-sm">
										<li>
											<a
												href="https://qafto.com/terms-and-conditions"
												className="text-gray-600 font-medium transition hover:opacity-75"
											>
												Terms & Conditions
											</a>
										</li>

										<li>
											<a
												href="https://qafto.com/privacy-policy"
												className="text-gray-600 font-medium transition hover:opacity-75"
											>
												Privacy Policy
											</a>
										</li>

										<li>
											<a
												href="https://qafto.com/refund-and-cancellation"
												className="text-gray-600 font-medium transition hover:opacity-75"
											>
												Refund & Cancellation Policy
											</a>
										</li>
										<li>
											<a
												href="https://qafto.com/ship-and-delivery"
												className="text-gray-600 font-medium transition hover:opacity-75"
											>
												Shipping & Delivery Policy
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="border-t-4 py-6 border-[#D9D9D9] text-gray-500">
							<p className="flex items-center justify-center font-medium text-sm">
								<IconCopyright size={18} className="me-1" /> Copyright 2024 Qafto. All rights reserved.
							</p>
						</div>
					</div>
				</footer>
			</div>
		</div>
	);
};

export default Footer;
