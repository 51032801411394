import { BehaviorSubject } from 'rxjs';
import moment from 'moment';
import axios from 'axios';

import { AUTH_URL } from './../config.js';
let currentUserSubject = new BehaviorSubject(null);
// const currentUserSubject = new BehaviorSubject(localStorage.getItem('currentUser'));

async function login(email, password) {
	try {
		const response = await axios.post(AUTH_URL + "/partner/login", {
			email, password
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}, withCredentials: true
		});
		const { message, ...user } = response.data;
		// localStorage.setItem('currentUser', JSON.stringify(user));
		currentUserSubject.next(JSON.stringify(user));
		return user ;
	} catch (error) {
		console.log(error);
		if (error.response) {
			if (error.response.data.message == 'Too many failed login attempts') {
				const message = `Too many failed login attempts, please try after ${moment.unix(error.response.data.tryAfter).format('MMMM Do YYYY, h:mm:ss a')}`
				throw message;
			} else {
				throw error.response.data.message;
			}
		} else {
			throw 'Something went wrong!';
		}
	}
}

async function register(first_name, email, password, role, mobile) {
	try {
		const response = await axios.post(AUTH_URL + "/partner/register", {
			first_name,
			email,
			password,
			role,
			mobile
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}, withCredentials: true
		});
		const user = JSON.stringify(response.data);
		// localStorage.setItem('currentUser', user);
		currentUserSubject.next(user);
		return user
	} catch (error) {
		console.log(error);
		if (error.response) {
			throw error.response.data.message;
		} else {
			throw 'Something went wrong!';
		}
	}
}

async function forgotPassword(email) {
	try {
		const response = await axios.post(AUTH_URL + '/partner/forgot-password', {
			email: email
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		});
		return response.data.message;
	} catch (error) {
		console.log(error);
		if (error.response) {
			if (error.response.data.message == 'Max attempts reached') {
				const message = `Max attempts limit reached, please try after ${moment.unix(error.response.data.tryAfter).format('MMMM Do YYYY, h:mm:ss a')}`
				throw message;
			} else {
				throw error.response.data.message;
			}
		} else {
			throw "Something went wrong!"
		}
	}
}


async function resetPassword(token, password, confirmPassword) {
	try {
		const response = await axios.post(AUTH_URL + '/partner/reset-password', {
			token: token,
			password: password,
			confirmPassword: confirmPassword
		}, {
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		})
		return response.data.message;
	} catch (error) {
		console.log(error);
		if (error.response) {
			throw error.response.data.message;
		} else {
			throw "Something went wrong!"
		}
	}
}

async function userVerify() {
	try {
		const response = await axios.get(AUTH_URL + '/partner/verify/user', {withCredentials : true});
		// .then( async response => {
			// console.log("akjshdjgasdgahsd", response?.data)
			return response?.data
		// });
	} catch (error) {
		console.log(error);
		if (error.response) {
			throw error.response.data.message;
		} else {
			throw 'Something went wrong';
		}
	}
}

function logout() {
	axios.get(AUTH_URL + "/partner/logout", {withCredentials: true});
	window.location.reload();
}

export const AuthService = {
	currentUser: currentUserSubject.asObservable(),
	get currentUserValue() { return currentUserSubject.value },
	login,
	userVerify,
	register,
	logout,
	forgotPassword,
	resetPassword
};