import axios from 'axios';
import { AuthService } from '../common/auth.service';
import { API_URL } from '../config';

const instance = axios.create();

instance.defaults.baseURL = API_URL;
if (AuthService.currentUserValue != null) {
	instance.defaults.headers.common['Authorization'] = 'Bearer ' + JSON.parse(AuthService.currentUserValue).token;
}

instance.defaults.headers.get['Accept'] = 'application/json';
instance.defaults.headers.post['Accept'] = 'application/json';
instance.defaults.onDownloadProgress = function (progressEvent) {
	// setShowProgressBar(true);
};
instance.defaults.withCredentials = true
instance.defaults.onUploadProgress = function (progressEvent) {
	// setShowProgressBar(true);
};

instance.interceptors.request.use(function (request) {
	// setShowProgressBar(true);
	return request;
}, function (error) {
	alert("unable to load");
});

instance.interceptors.response.use(function (response) {
	return response;
}, function (error) {
	if (error.response) {
		if (error.response.status == 401) {

			AuthService.logout();
			return;
		}
	}
	throw error;
});

export const dbCall = instance;