import React from 'react'
import { useContext } from 'react'
import { context } from "../context/Context";
import { Navigate } from 'react-router-dom';
function ProfileRedirect() {
  const { userProfileComplete } = useContext(context);
  return (
    <Navigate to={userProfileComplete ? "/user/profile" : "/user/edit-profile"} replace />

  )
}

export default ProfileRedirect