import { Outlet, useNavigate } from "react-router-dom";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { useContext, useEffect } from "react";
import { context } from "../context/Context";

function GuestLayout() {
	const { user } = useContext(context);
	const navigate = useNavigate();
	useEffect(() => {
		if (user && user.id) {
			navigate("/user/profile");
		}
	}, [user, navigate]);
	return (
		<div id="container" className="bg-white">
			<Header />

			<main className="mt-20">
				<Outlet />
			</main>

			<Footer />
		</div>
	)
}

export default GuestLayout;